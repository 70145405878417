import React from 'react';
import { motion } from "framer-motion"
import './styles/main.scss';

function App() {
  const transitionOne = {
    duration: 0.8,
    delay: 0.5,
    ease: [0, 0.71, 0.2, 1.01]
  };
  const transitionTwo = {
    duration: 0.8,
    delay: 0.8,
    ease: [0, 0.71, 0.2, 1.01]
  };
  const transitionThree = {
    duration: 1,
    delay: 1.5,
    ease: [0, 0.71, 0.2, 1.01]
  };
  return (
    <>
      <div className='home'>
        <motion.div className='logo' 
          initial={{ opacity: 0, scale: 0.5 }} 
          animate={{ opacity: 1, scale: 1 }}
          transition={transitionOne}>
          <img src='/images/icon_white.svg' alt='Logo'/>
        </motion.div>
        <motion.div className='text' 
          initial={{ opacity: 0, y: 100 }} 
          animate={{ opacity: 1, y: 0 }}
          transition={transitionTwo}>
          Coming Soon
        </motion.div>
      </div>
      <motion.div className='powered'
          initial={{ opacity: 0, y: -100 }} 
          animate={{ opacity: 1, y: 0 }}
          transition={transitionThree}>
        Powered by <a href='https://digitaldata.mn' target='_blank' rel='noreferrer'><span>Digital Data LLC</span></a>
      </motion.div>
    </>
  );
}

export default App;
